@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-guide-date-select-value {
        cursor: pointer;
        padding: 0 $spacer;

        .mdi {
            margin-right: $spacer*0.5;
        }
    }

    .tv-channel-guide-date-select-value-date {
        font-weight: $font-weight-medium;
        margin-left: $spacer*0.5;
        margin-right: $spacer*0.5;
    }

    .tv-channel-guide-date-select-datalist {
        background: $body-bg;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: row;
        height: calc((1.5em + 2px + 2rem) * 7);
        max-height: calc((1.5em + 2px + 2rem) * 7);
        overflow: hidden;
        position: absolute;
        z-index: 10000;

        .tv-channel-guide-date-select-option {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-weight: $font-weight-bold;
            height: calc(1.5em + 2px + 2rem);
            padding: 0 $spacer*1.5;

            &:hover, &.selected {
                background: rgba($white, 0.05);
            }
        }
    }
}
