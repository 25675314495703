.modal {
    .modal-dialog.fetching {
        cursor: wait;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        box-shadow: $box-shadow;

        .progress {
            background-color: rgba($white, 0.05);
            display: flex;
            height: 4px;
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;

            &::before {
                content: "";
                background-color: $primary;
                height: 4px;
                margin: 0;
                width: 0;
            }

            &.progress-25::before {
                width: 25%;
            }

            &.progress-50::before {
                width: 50%;
            }

            &.progress-75::before {
                width: 75%;
            }

            &.progress-100::before {
                width: 100%;
            }

            &.progress-indeterminate::before {
                animation: progress-button-animation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                width: 100%;
            }
        }
    }
}
