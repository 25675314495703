@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.network-state {
    background: rgba($danger, 0.95);
    bottom: $spacer;
    left: $spacer;
    padding: $spacer*1.5;
    position: absolute;
    z-index: 100;

    .message {
        padding-left: $spacer;
    }
}
