@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-guide-program {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: $spacer*0.5 $spacer;
        position: relative;
        scroll-snap-align: start;

        &.archived:not(.suspense) {
            cursor: pointer;

            &:hover {
                background: rgba($white, 0.05);
            }

            &::after {
                content: "\f02da";
                font-family: "Material Design Icons";
                font-size: $font-size-base*1.5;
                padding-left: $spacer;
            }
        }

        &.playing:not(.suspense) {
            background-color: rgba($white, 0.05);
        }

        &.suspense {
            height: 65px;

            &::before {
                clip-path: url(#tvChannelGuideProgramSuspense);
            }
        }

        .tv-channel-guide-program-content {
            flex-grow: 1;
            overflow: hidden;
        }

        .tv-channel-guide-program-row {
            display: flex;
            padding-bottom: $spacer*0.25;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .tv-channel-guide-program-start {
            font-weight: $font-weight-medium;
            width: 100px;
            min-width: 100px;
        }

        .tv-channel-guide-program-title {
            flex-grow: 1;
            font-weight: $font-weight-medium;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .tv-channel-guide-program-duration {
            font-size: $font-size-sm;
            width: 100px;
            min-width: 100px;
        }

        .tv-channel-guide-program-description {
            flex-grow: 1;
            font-size: $font-size-sm;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
