@import "import-bootstrap";

@-webkit-keyframes progress-button-animation {
    0% {
        margin-left: 0;
        margin-right: 100%;
    }
    50% {
        margin-left: 25%;
        margin-right: 0;
    }
    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

@keyframes progress-button-animation {
    0% {
        margin-left: 0;
        margin-right: 100%;
    }
    50% {
        margin-left: 25%;
        margin-right: 0;
    }
    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

html, body {
    background-color: $body-bg;
    color: $body-color;
}

@import "modal";

.theme-rebit-material-dark {
    @import "forms";
    @import "buttons";
    @import "card";
    @import "input-group";
    @import "screen";
    @import "section";

    .navbar-toggler {
        border: 0;
        border-radius: 0;
    }

    button.close {
        background: transparent;
        border: none;
        border-radius: 50%;
        color: inherit;
        padding: $spacer $spacer*1.5;

        &:hover {
            background: rgba($white, 0.1);
        }

        .mdi {
            font-size: $font-size-base*1.5;
        }
    }

    .section:not(:last-of-type) {
        margin-bottom: $spacer*3;
    }

    .panel {
        box-shadow: $box-shadow;
        position: relative;

        input[readonly] {
            pointer-events: none;
        }

        &.fetching {
            cursor: wait;
        }

        &.invalid:not(.fetching)::before {
            content: "";
            background-color: $danger;
            display: flex;
            height: 4px;
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100;
        }

        .panel-head {
            background: rgba($white, 0.05);
            font-size: $font-size-lg;
            margin-bottom: 1px;
            padding: $spacer*1.5 $spacer*3;
        }

        .panel-content {
            background: rgba($white, 0.05);
            padding: $spacer*1.5 $spacer*3;

            .panel-title {
                font-size: 1.1rem;
                margin-bottom: $spacer*3;
                text-align: center;
            }
        }

        .panel-foot {
            align-items: stretch;
            background: rgba($white, 0.05);
            display: flex;
            flex-direction: column;
            margin-top: 1px;
            padding: $spacer $spacer*1.5;

            @include media-breakpoint-up(lg) {
                flex-direction: row-reverse;
                justify-content: space-between;
            }

            .btn {
                margin-bottom: $spacer;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            .group {
                align-items: stretch;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-up(lg) {
                    flex-direction: row-reverse;
                }
            }
        }

        .progress {
            background-color: rgba($white, 0.05);
            display: flex;
            height: 4px;
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;

            &::before {
                content: "";
                background-color: $primary;
                height: 4px;
                margin: 0;
                width: 0;
            }

            &.progress-25::before {
                width: 25%;
            }

            &.progress-50::before {
                width: 50%;
            }

            &.progress-75::before {
                width: 75%;
            }

            &.progress-100::before {
                width: 100%;
            }

            &.progress-indeterminate::before {
                animation: progress-button-animation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                width: 100%;
            }
        }
    }

    .screen-document {
        .collapse-control {
            margin-bottom: $spacer;
        }

        .collapse-control + ol {
            margin-bottom: $spacer * 1.5;
        }

        .collapse-control + ol ol,
        .collapse-control + ol ul {
            margin-bottom: $spacer*0.5;
            margin-top: $spacer*0.5;
        }

        .collapse-control + ol li p {
            margin-bottom: $spacer*0.5;
        }
    }

    .list-style-none {
        list-style-type: none;
        padding-left: 0;
    }

    .list-style-lower-alpha {
        list-style-type: lower-alpha;
    }

    .collapse:target {
        display:block;
    }

    .rcs-custom-scrollbar {
        width: 12px;

        .rcs-inner-container {
            position: relative;

            &::after {
                content: "";
                background: red;
                height: 100px;
                position: absolute;
                width: 100px;
                z-index: 10000;
            }
        }

        .rcs-custom-scroll-handle {
            cursor: pointer;
            width: 12px;

            .rcs-inner-handle {
                background-color: $custom-scroll-color;
                border-radius: 6px;
                pointer-events: auto;
            }
        }
    }
}
