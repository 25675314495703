@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$layout-my-account-guest-link-color: $gray-400 !default;
$layout-my-account-guest-link-transition: color .25s ease-in-out !default;
$layout-my-account-guest-link-active-color: $white !default;

.theme-rebit-material-dark {
    .layout-my-account-guest {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: space-evenly;

        .layout-head {
            margin-bottom: $spacer*3;
            margin-top: $spacer*3;

            @include media-breakpoint-up(lg) {
                margin-bottom: $spacer*6;
            }

            .layout-title {
                display: flex;
                justify-content: center;
                min-height: 300px;
                max-height: 20vh;
                text-align: center;

                .logo {
                    height: auto;
                    object-fit: contain;
                    max-width: 100%;
                }
            }
        }

        .layout-foot {
            margin-top: auto;
            padding-bottom: $spacer*1.5;
            padding-top: $spacer*1.5;
            text-align: center;

            a {
                color: $layout-my-account-guest-link-color;
                display: block;
                padding: $spacer*0.25 $spacer*0.5;
                transition: $layout-my-account-guest-link-transition;

                @include media-breakpoint-up(lg) {
                    display: inline-block;
                }

                &:hover {
                    color: $layout-my-account-guest-link-active-color;
                }
            }
        }
    }
}
