@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .service-modal {
        .tv-package-view-description {
            font-weight: $font-weight-medium;
            margin-bottom: $spacer*1.5;
        }

        .service-card-tv-package-channel-count > i {
            margin-right: $spacer*0.5;
        }

        .tv-package-view-tv-channel {
            margin-bottom: $spacer;
            text-align: center;
            transition: transform .25s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            .tv-package-view-tv-channel-logo {
                margin-bottom: $spacer*0.25;
                max-width: 80%;
            }

            .tv-package-view-tv-channel-title {
                display: block;
                font-weight: $font-weight-medium;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                white-space: nowrap;
                width: 100%;
                max-width: 100%;
            }
        }

    }
}
