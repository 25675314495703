@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .screen-eshop-checkout {
        .section-shop-checkout-submit {
            .container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
