@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .search-input {
        bottom: 0;
        position: absolute !important;
        top: 0;

        &.open {
            left: 0;
            right: 0;
        }

        &:not(.open) {
            border: none !important;
        }

        &:not(.open) label {
            display: none;
        }

        &:not(.open) .form-control {
            display: none;
        }

        .form-control + .input-group-append .input-group-text {
            cursor: pointer;
            transition: none !important;
        }
    }
}
