@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$tv-channel-card-background: transparent !default;
$tv-channel-card-border: none !default;
$tv-channel-card-color: $body-color !default;
$tv-channel-card-padding-x: $spacer*1.5 !default;
$tv-channel-card-padding-y: $spacer !default;

$tv-channel-card-active-background: rgba($gray-400, 0.05) !default;

.theme-rebit-material-dark {
    .tv-channel-card {
        align-items: center;
        background: $tv-channel-card-background;
        border: $tv-channel-card-border;
        color: $tv-channel-card-color;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding: $tv-channel-card-padding-y $tv-channel-card-padding-x;
        position: relative;
        scroll-snap-align: start;
        text-align: left;
        width: 100%;
        max-width: 100%;

        &:hover, &.playing {
            background: $tv-channel-card-active-background;
        }

        &.archived:not(.suspense) {
            &::after {
                content: "\f02da";
                font-family: "Material Design Icons";
                font-size: $font-size-base*1.5;
            }
        }

        &.protected:not(.suspense) {
            &::after {
                content: "\f0341";
                font-family: "Material Design Icons";
                font-size: $font-size-base*1.5;
            }
        }

        &:not(.subscribed):not(.suspense) {
            opacity: 0.5;

            &::after {
                content: "\f019b";
                font-family: "Material Design Icons";
                font-size: $font-size-base*1.5;
            }
        }

        .progress {
            background-color: rgba($gray-400, 0.05);
            height: $tv-channel-control-progress-height;
            margin: $spacer*0.25 0;

            .progress-bar {
                background-color: $secondary;
                height: inherit;
            }
        }

        .tv-channel-card-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0 $spacer*1.5;
            min-width: 0;
        }

        .tv-channel-card-symbol {
            height: $tv-channel-control-icon-height;
            max-height: $tv-channel-control-icon-height;
            margin-left: $spacer*0.5;
            margin-right: $spacer*0.5;
            width: $tv-channel-control-icon-height;
            max-width: $tv-channel-control-icon-height;
        }

        .tv-channel-card-program-title {
            min-height: $line-height-base * $font-size-base;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .tv-channel-card-title {
            min-height: $line-height-base * $font-size-base;
            margin-bottom: $spacer*0.25;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
