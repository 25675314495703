.form-group {
    border-bottom: 2px solid rgba($white, 0.05);
    padding-top: $spacer;
    position: relative;
    transition: border-color .25s ease-in-out, color .25s ease-in-out;

    &.has-focus {
        border-color: $primary;
    }

    label {
        backface-visibility: hidden;
        bottom: $input-padding-y + $spacer * 0.5;
        font-size: $font-size-base;
        height: $input-height;
        left: $input-padding-x - 0.1rem;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: $input-padding-x;
        top: $input-padding-y + $spacer * 0.5;
        transition: transform .25s ease-in-out;
        transform-origin: 0 0;
        user-select: none;
        white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
        &.has-input-group-prepend label {
            margin-left: $input-padding-x * 2 + $input-font-size + 0.1rem;
        }
    }

    &.has-focus label,
    &.has-value label {
        font-weight: $font-weight-medium;
        transform: scale(0.75) translateY(-25%);
    }

    &.has-value:not(.has-focus) label {
        color: $gray-400;
    }

    &.is-invalid.is-touched:not(.has-focus) label {
        color: $danger;
    }

    &.is-invalid.is-touched:not(.has-focus) {
        border-color: $danger;
    }

    .form-control {
        background: transparent;
        border: 0;
        color: $white;
        font-size: $font-size-base;
        position: relative;
    }

    &.has-focus .form-control {
        border-color: $primary;
    }

    .input-group .input-group-prepend {
        margin-right: 0;
    }

    .input-group .input-group-text {
        background: transparent;
        border: 0;
        color: $white;
        transform: translateY(-$spacer / 1.9);
    }

    &.has-focus .input-group .input-group-text {
        border-color: $primary;
    }

    .input-group .input-group-icon {
        font-size: $font-size-base;
    }
}
