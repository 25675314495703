@import "src/WebApp/variables";
@import "src/WebApp/bootstrap";

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.input-group-icon.mdi {
    line-height: 1;
}

.suspense {
    position: relative;

    &::before {
        content: "";
        animation: 1.5s shine linear infinite;
        background: rgba($gray-400, 0.05);
        background: linear-gradient(110deg, rgba($gray-400, 0.05) 8%, rgba($gray-400, 0.1) 18%, rgba($gray-400, 0.05) 33%);
        background-size: 200% 100%;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
}

.btn .countdown {
    margin-left: $spacer*0.5;
}

.nav-card {
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: $box-shadow;
    color: $body-color;
    display: flex;
    flex-direction: column;
    padding: $spacer*3 $spacer*1.5;

    &:focus, &:hover {
        background: $primary;
        color: $body-color;
    }

    .mdi {
        font-size: $font-size-lg*3;
    }

    .nav-card-title {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
    }
}

.screen-my-account-signin,
.screen-my-subscription-management,
.screen-tv-package-list {
    .campaign {
        align-items: center;
        background: rgba(255,255,255,0.05);
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
        color: $white;
        display: flex;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        position: relative;
        overflow: hidden;

        .mdi {
            margin-right: 1ch;
        }

        strong {
            font-weight: 500;
            margin-right: 1ch;
            text-transform: uppercase;
        }
    }
}

.snow {
    display: none;
}

@include media-breakpoint-up(lg) {
    .snow {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    .snowflake {
        --size: 1vw;
        width: var(--size);
        height: var(--size);
        /*
        background: white;
        border-radius: 50%;
        */
        position: absolute;
        top: -5vh;
    }

    @keyframes snowfall {
        0% {
            transform: translate3d(var(--left-ini), 0, 0);
        }
        100% {
            transform: translate3d(var(--left-end), 200px, 0);
        }
    }

    @for $i from 1 through 50 {
        .snowflake:nth-child(#{$i}) {
            --size: #{random(3) * 0.2}vw;
            --left-ini: #{random(20) - 10}vw;
            --left-end: #{random(20) - 10}vw;
            left: #{random(100)}vw;
            animation: snowfall #{2 + random(4)}s linear infinite;
            animation-delay: -#{random(4)}s;
        }
    }

    //.snowflake:nth-child(4n) {
    //    background: #FAB812;
    //}
    //
    //.snowflake:nth-child(4n+1) {
    //    background: #FB6351;
    //}
    //
    //.snowflake:nth-child(4n+2) {
    //    background: #C938AE;
    //}
    //.snowflake:nth-child(4n+3) {
    //    background: #674BED;
    //}

    /* added small blur every 6 snowflakes*/
    .snowflake:nth-child(6n) {
        filter: blur(1px);
    }
}
