@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$tv-client-card-grid-view-height: $spacer*3.5 + $font-size-base*3*$line-height-base + $font-size-sm*$line-height-base*2 !default;

$tv-client-card-grid-view-suspense-clip-path: url(#suspenseTvClientCard) !default;

.theme-rebit-material-dark {
    .tv-client-card-grid-view {
        .tv-client-card {
            height: $tv-client-card-grid-view-height;
            margin-bottom: $grid-gutter-width;

            &.suspense::before {
                clip-path: $tv-client-card-grid-view-suspense-clip-path;
            }
        }
    }
}
