@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .service-card.type-television-package {
        .service-card-tv-package-channel-count {
            align-self: flex-start;
            cursor: pointer;
            font-weight: $font-weight-medium;
            margin-bottom: $spacer*1;

            & > i {
                margin-right: $spacer*0.5;
            }
        }
    }
}
