@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$service-card-variant-select-padding-x: 0 !default;
$service-card-variant-select-padding-y: $spacer*0.5 !default;

$service-card-variant-select-datalist-background: $body-bg !default;
$service-card-variant-select-datalist-box-shadow: $box-shadow !default;
$service-card-variant-select-datalist-z-index: 2000 !default;

$service-card-variant-select-option-color: $body-color !default;
$service-card-variant-select-option-padding-x: $spacer !default;
$service-card-variant-select-option-padding-y: $spacer*0.5 !default;

$service-card-variant-select-option-active-background: rgba($white, 0.1) !default;

.theme-rebit-material-dark {
    .service-card-variant-select {
        cursor: pointer;
        display: block;
        padding: $service-card-variant-select-padding-y $service-card-variant-select-padding-x;

        .service-card-variant-select-value {
            padding-right: $spacer*2;
            position: relative;
            text-align: center;

            &::after {
                content: "\F0140";
                font-family: "Material Design Icons";
                font-size: $font-size-lg;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.open .service-card-variant-select-value {
            &::after {
                content: "\F0143";
            }
        }

        .service-card-variant-select-datalist {
            background: $service-card-variant-select-datalist-background;
            box-shadow: $service-card-variant-select-datalist-box-shadow;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            max-width: 100%;
            z-index: $service-card-variant-select-datalist-z-index;
        }

        .service-card-variant-select-option {
            color: $service-card-variant-select-option-color;
            padding: $service-card-variant-select-option-padding-y $service-card-variant-select-option-padding-x;
            white-space: nowrap;

            &:hover {
                background: $service-card-variant-select-option-active-background;
            }
        }

        .service-card-variant-select-price {
            font-size: $font-size-lg;
            font-weight: $font-weight-medium;
            line-height: $line-height-lg;
            margin-right: $spacer*0.25;

            &.discount {
                color: rgba($white, 0.5);
                margin-right: $spacer*0.5;
                text-decoration: line-through;
            }
        }

        .service-card-variant-select-payment-periodicity {
            margin-left: $spacer*0.25;
        }

        .service-card-variant-select-reference {
            color: rgba($white, 0.5);
            font-size: $font-size-base;
            text-decoration: line-through;
            text-align: center;
        }
    }
}
