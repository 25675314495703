@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .screen-tv-client-management {
        .section-title {
            justify-content: space-between;

            .screen-tv-client-management-client-count {
                font-weight: $font-weight-medium;
                margin-left: $spacer*0.5;
                white-space: nowrap;
            }
        }
    }
}
