@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$service-card-background: rgba($white, 0.05) !default;
$service-card-box-shadow: $box-shadow !default;
$service-card-padding-x: $spacer*1.5 !default;
$service-card-padding-y: $spacer*1.5 !default;
$service-card-transition: background-color .25s ease-in-out !default;

$service-card-active-background: rgba($white, 0.1) !default;

.theme-rebit-material-dark {
    .service-card {
        align-items: center;
        background: $service-card-background;
        box-shadow: $service-card-box-shadow;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer;
        padding: $service-card-padding-y $service-card-padding-x;
        transition: $service-card-transition;

        &:hover {
            background: $service-card-active-background;
        }

        .service-card-title {
            cursor: pointer;
            font-size: $font-size-lg;
            font-weight: $font-weight-medium;
            line-height: $line-height-lg;
            margin-bottom: $spacer;
        }

        .service-card-description {
            cursor: pointer;
            display: none;
            font-size: $font-size-base;
            line-height: $line-height-base;
            margin-bottom: $spacer;
            text-align: center;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            &.truncate {
                @include media-breakpoint-up(lg) {
                    display: -webkit-box;
                    height: $line-height-base*$font-size-base*2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -moz-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }

        .service-card-variant {
            margin-bottom: $spacer*1.5;
        }
    }
}
