@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-video {
        .tv-channel-video-archive-control {
            .control-play-next-program {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;

                .control-play-next-program-head {
                    font-size: $font-size-base*1.5;
                    font-weight: $font-weight-medium;
                    margin-bottom: $spacer;
                    text-transform: lowercase;
                }

                .control-play-next-program-title {
                    font-size: $font-size-base*3;
                    font-weight: $font-weight-bold;
                    margin-bottom: $spacer*0.5;
                }

                .control-play-next-program-duration {
                    font-size: $font-size-base;
                    font-weight: $font-weight-medium;
                    margin-bottom: $spacer*3;
                    text-transform: lowercase;
                }

                .control-play-next-program-play,
                .control-play-next-program-stop {
                    align-items: center;
                    background: rgba($gray-900, 0.5);
                    display: inline-flex;
                    font-size: $font-size-lg !important;
                    margin: 0 $spacer;
                    padding: $spacer*0.5 $spacer*3 !important;

                    &:focus, &:hover {
                        background: rgba($gray-700, 0.5);
                    }

                    .mdi {
                        margin-right: $spacer*0.5;
                    }
                }
            }

            &.fullscreen .control-play-next-program {
                transform: scale(1.5);
            }
        }
    }
}
