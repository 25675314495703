@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$my-subscription-card-active-background: rgba($white, 0.1) !default;

.theme-rebit-material-dark {
    .my-subscription-card {
        &.inquiry {
            background: $my-subscription-card-active-background;
        }
    }
}
