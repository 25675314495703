.section {
    .section-title {
        align-items: center;
        background: rgba($white, 0.05);
        box-shadow: $box-shadow;
        display: flex;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer;
        padding: $spacer $spacer*1.5;
        text-transform: uppercase;
    }
}
