@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .video-volume-control {
        align-items: center;
        display: flex;
        flex-direction: row;

        &.show {
            padding-right: $spacer;
        }

        .form-control-range {
            cursor: pointer;
            display: none;
            margin: 0 !important;
            width: 100px;
        }

        &.show .form-control-range {
            display: block;
        }
    }
}
