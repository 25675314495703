// color system
$white: #ffffff !default;

$primary: #174896 !default;
$secondary: #f36d44 !default;
$success: #007e33 !default;
$warning: #ff8800 !default;
$danger: #cc0000 !default;

$yiq-contrasted-threshold: 160 !default;

// options

$enable-caret: false !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-responsive-font-sizes: true !default;
$enable-validation-icons: false !default;

// body

$body-bg: #f5f7fb !default;
$body-color: #333333 !default;

// grid breakpoints

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px
) !default;

// grid containers

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1240px
) !default;

// grid columns

$grid-gutter-width: 40px !default;

// components

$border-radius: .75rem !default;
$border-radius-lg: 1rem !default;
$border-radius-sm: .5rem !default;

// links

$link-color: $body-color !default;
$link-hover-color: $secondary !default;
$link-hover-decoration: none !default;

// typography

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Barlow", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;

$headings-font-weight: $font-weight-bold !default;

// tables

$table-striped-order: even !default;

// buttons + forms

$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: 1.5rem !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-box-shadow: none !default;

// buttons

$btn-padding-x: 3rem !default;

$btn-font-weight: $font-weight-medium !default;

$btn-border-radius: 25rem !default;
$btn-border-radius-lg: $btn-border-radius !default;
$btn-border-radius-sm: $btn-border-radius !default;

// forms

$input-bg: $white !default;

$input-group-addon-bg: $input-bg !default;

// navbar

$navbar-light-color: $link-color !default;
$navbar-light-hover-color: $link-hover-color !default;
$navbar-light-active-color: $link-hover-color !default;
$navbar-light-disabled-color: rgba($link-color, 0.5) !default;

// jumbotron

$jumbotron-padding-x: 3rem !default;
$jumbotron-padding-y: 2rem !default;
$jumbotron-bg: transparent !default;

// gradients

$gradient-primary: linear-gradient(240deg, rgba(164, 97, 194, 1) 0%, 54%, rgba(21, 68, 144, 1) 75%, 87.5%, rgba(21, 48, 113, 1) 100%);
$gradient-secondary: linear-gradient(to right, #e57a50, #f0a450);

@import "~bootstrap/scss/_variables";
