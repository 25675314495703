@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

@keyframes toast-progressbar {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.toast {
    align-items: center;
    background-color: $body-bg;
    color: $body-color;
    display: flex;
    left: 0;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9000;

    &::before {
        flex-grow: 0;
        font-size: $font-size-base*3;
        padding-left: $spacer;
        padding-right: $spacer;
    }

    &.bg-danger::before {
        content: "\F05D6";
        font-family: "Material Design Icons";
    }

    &.bg-success::before {
        content: "\F012C";
        font-family: "Material Design Icons";
    }

    .close {
        color: $white;
        font-size: $font-size-base * 1.5;
        margin: $spacer*1.5;
        padding: $spacer $spacer*1.5;

        &:hover {
            background: rgba($white, 0.25);
        }
    }

    .progress {
        background: rgba($white, 0.1);
        bottom: 0;
        height: $toast-progress-height;
        left: 0;
        position: absolute;
        right: 0;

        .progress-bar {
            animation-fill-mode: forwards;
            animation-name: toast-progressbar;
            animation-timing-function: linear;
            background-color: rgba($white, 0.5);
            height: inherit;
        }
    }

    .toast-content {
        flex-grow: 1;
        padding-left: $spacer;
        padding-right: $spacer;
    }

    .toast-title {
        font-weight: $font-weight-medium;
        margin-bottom: $spacer;
    }
}
