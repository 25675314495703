@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-video {
        background-color: $black;
        position: relative;
        z-index: 1;

        &.ended > .video {
            filter: blur(15px);
        }
    }
}
