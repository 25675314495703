@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

body.with-shopping-cart-panel {
    padding-bottom: $font-size-base*$line-height-base + $font-size-lg*$line-height-lg + $spacer*3.5;

    .page-container {
        min-height: subtract(100vh, $font-size-base*$line-height-base + $font-size-lg*$line-height-lg + $spacer*3.5);
    }
}

.theme-rebit-material-dark {
    .shopping-cart-panel {
        background: $primary;
        bottom: 0;
        box-shadow: $box-shadow;
        left: 0;
        padding-bottom: $spacer*1.5;
        padding-top: $spacer*1.5;
        position: fixed;
        right: 0;
        z-index: 10;

        &.open {
            @include media-breakpoint-down(md) {
                top: 0;
            }
        }

        .container {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &.open .container {
            @include media-breakpoint-down(md) {
                height: 100%;
            }
        }

        .shopping-cart-panel-collapse {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        &.open .shopping-cart-panel-collapse {
            @include media-breakpoint-down(md) {
                display: flex;
                align-items: stretch;
                flex-direction: column;
            }
        }

        .shopping-cart-panel-collapse-switch {
            display: block;
            font-size: $font-size-base*1.5;
            padding-left: $spacer;
            padding-right: $spacer;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .shopping-cart-panel-block {
            display: none;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            line-height: $line-height-lg;
            justify-content: space-between;
            margin: 0 $spacer;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
            }

            .discount {
                color: rgba($white, 0.5);
                margin-right: $spacer*0.5;
                text-decoration: line-through;
            }
        }

        &.open .shopping-cart-panel-block {
            @include media-breakpoint-down(md) {
                border-bottom: 1px solid rgba($white, 0.05);
                display: flex;
                padding-bottom: $spacer*0.5;
                padding-top: $spacer*0.5;
            }
        }

        .shopping-cart-control-block-title {
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            line-height: $line-height-base;
            margin-bottom: $spacer*0.5;
            text-transform: uppercase;
        }

        .shopping-cart-panel-control {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
}
