@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .screen-my-account-recovery-initiation {
        .recovery-method {
            margin: $spacer*1.5;

            label {
                cursor: pointer;
                width: 100%;
            }

            strong {
                display: block;
                font-weight: $font-weight-medium;
            }
        }
    }
}
