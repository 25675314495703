@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$service-modal-height: subtract(100vh, $spacer*6, true) !default;
$service-modal-max-height: subtract(100vh, $spacer*6, true) !default;

.theme-rebit-material-dark {
    .service-modal {
        display: flex;
        flex-direction: column;
        height: $service-modal-height;
        max-height: $service-modal-max-height;
        margin-top: $spacer*3;
        overflow: hidden;

        .modal-header {
            background-color: $body-bg;
            position: sticky;
            top: 0;
            z-index: 1000;
        }

        .service-modal-description {
            margin-bottom: $spacer*1.5;
        }
    }
}
