.card {
    .card-header {
        border-radius: 0;
        text-transform: uppercase;
    }

    .card-footer {
        border-radius: 0;
    }

    .progress-line {
        background-color: rgba($white, 0.05);
        display: flex;
        height: 4px;
        left: -$card-border-width;
        margin: 0;
        position: absolute;
        right: -$card-border-width;
        top: -$card-border-width;

        &::before {
            content: "";
            animation: progress-button-animation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            background-color: $primary;
            height: 4px;
            margin: 0;
            width: 100%;
        }
    }
}
