@import "src/WebApp/variables";

.layout-tv-channel-video {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    width: 100vw;

    &.extended {
        height: 150vh;
    }

    .layout-head {
        background-color: $page-header-bg;
        box-shadow: $box-shadow;
    }

    .layout-head .navbar {
        flex-grow: 1;
    }

    .layout-head .navbar-nav {
        margin-bottom: $spacer;
        margin-top: $spacer;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .layout-head .nav-link {
        align-items: center;
        border-bottom: 1px solid rgba($white, 0.05);
        border-radius: 0;
        display: flex;
        padding: $spacer*0.25 $spacer*1.5;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            flex-direction: column;
            padding: 0 $spacer;
        }
    }

    .layout-head .nav-link .nav-icon {
        font-size: $font-size-lg;
        margin-right: $spacer;

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg * 2;
            margin-right: 0;
        }
    }

    .layout-head .navbar-brand .logo {
        max-height: 50px;
    }

    .app-version {
        font-size: $font-size-sm;
        padding: 0 $spacer $spacer*0.25 $spacer;
        margin-top: -$spacer;
    }
}
