@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        .tv-channel-card {
            &.suspense {
                height: 96px;

                &::before {
                    clip-path: url(#suspenseTvChannelCard);
                }
            }
        }
        .tv-channel-list-control-panel {
            align-items: stretch;
            display: flex;
            height: $tv-channel-list-header-height;
            margin-bottom: $spacer;
            position: relative;
        }

        .tv-channel-list-search-input {
            margin-bottom: 0;
            right: 0;

            &.open {
                background-color: rgba($white, 0.05);
            }

            .form-control + .input-group-append .input-group-icon {
                font-size: $font-size-lg !important;
                font-weight: $font-weight-medium !important;
            }

            .input-group-text {
                margin-top: $spacer*0.25;
            }
        }

        .tv-channel-list-title {
            align-items: center;
            background: rgba($white, 0.05);
            display: flex;
            flex-grow: 1;
            font-weight: $font-weight-bold;
            padding: $spacer*0.5 $spacer;
            text-transform: uppercase;
        }

        .tv-channel-list-search-input.open + .tv-channel-list-title {
            visibility: hidden;
        }

        .fetching {
            display: block;
            text-align: center;
        }

        .fetching > i {
            font-size: $font-size-lg*3;
        }

        .rcs-inner-container {
            scroll-snap-type: y proximity;
        }
    }
}
