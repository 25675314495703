@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .parental-lock-portal {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;

        .modal-content {
            border-radius: 0;
            border-width: 0;
            box-shadow: $box-shadow;
            padding-bottom: $spacer*3;
            padding-top: $spacer*3;
        }

        .code-input-control {
            background: rgba($white, 0.05);
            border: 0;
            border-radius: 0;
            color: $white;
            font-size: $font-size-base*9;
            font-family: Password;
            height: 20vw;
            letter-spacing: $spacer*6;
            padding: 0 $spacer*6;
            text-align: center;
            max-width: 100%;
        }
    }
}
