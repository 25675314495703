@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$tv-client-card-background: rgba($white, 0.05) !default;
$tv-client-card-border: none !default;
$tv-client-card-box-shadow: $box-shadow !default;
$tv-client-card-color: $body-color !default;
$tv-client-card-font-size: $font-size-sm !default;
$tv-client-card-font-weight: $font-weight-medium !default;
$tv-client-card-padding-x: $spacer !default;
$tv-client-card-padding-y: $spacer !default;
$tv-client-card-transition: background .25s ease-in-out !default;

$tv-client-card-active-background: rgba($white, 0.1) !default;

.theme-rebit-material-dark {
    .tv-client-card {
        align-items: center;
        background: $tv-client-card-background;
        border: $tv-client-card-border;
        box-shadow: $tv-client-card-box-shadow;
        color: $tv-client-card-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        padding: $tv-client-card-padding-y $tv-client-card-padding-x;
        transition: $tv-client-card-transition;
        width: 100%;

        &:focus, &:hover {
            background: $tv-client-card-active-background;
        }

        .tv-client-card-symbol {
            font-size: $font-size-base*3;
            margin-bottom: $spacer;
        }

        .tv-client-card-title {
            font-size: $tv-client-card-font-size;
            font-weight: $tv-client-card-font-weight;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }

        .tv-client-card-heartbeat {
            font-size: $tv-client-card-font-size;
            font-weight: $tv-client-card-font-weight;
            margin-top: $spacer*0.25;
            padding: $spacer*0.25 $spacer*0.5;
        }
    }
}
