@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .eshop-inquiry-table-view {
        .table {
            background-color: transparent;

            .discount {
                color: rgba($white, 0.5);
                margin-right: $spacer*0.5;
                text-decoration: line-through;
            }

            thead th, thead td {
                border: none;
                font-weight: $font-weight-medium;
            }

            tbody th, tbody td {
                font-weight: $font-weight-normal;
            }

            tfoot th, tfoot td {
                font-weight: $font-weight-bold;
            }

            @include media-breakpoint-down(sm) {
                thead {
                    display: none;
                }

                tbody th, tfoot th {
                    border-bottom: $table-border-width solid $table-dark-border-color;
                    display: flex;
                    font-weight: $font-weight-medium;
                }

                tbody td, tfoot td {
                    display: flex;
                    justify-content: space-between;

                    &::before {
                        content: attr(data-label);
                    }
                }

                tbody tr:nth-child(1) > :nth-child(1) {
                    border-top: none;
                }
            }

            @include media-breakpoint-up(md) {
                tr > :nth-child(2) {
                    width: 20%;
                }
                tr > :nth-child(3), tr > :nth-child(4) {
                    text-align: right;
                    width: 20%;
                }

                thead tr > :nth-child(2) {
                    text-align: right;
                }
            }
        }

        .shop-service-commitment-select {
            justify-content: flex-end;

            .btn {
                font-weight: normal;
                text-transform: none;
            }

            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
    }
}
