@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .video-tv-channel-control {
        align-items: center;
        display: flex;
        flex-direction: row;

        .video-tv-channel-control-logo {
            max-height: 2rem;
            padding: 0 $spacer*0.5;
        }

        .video-tv-channel-control-title {
            font-weight: $font-weight-bold;
            overflow: hidden;
            padding: 0 $spacer;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
