@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-video {
        .tv-channel-video-live-control {
            .control-nav-channel {
                &.control-next-channel {
                    right: $spacer*1.5;
                }

                &.control-prev-channel {
                    left: $spacer*1.5;
                }
            }
        }
    }
}
