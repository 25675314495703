@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

$payment-method-background: rgba($white, 0.05) !default;
$payment-method-box-shadow: $box-shadow !default;
$payment-method-padding-x: $spacer*1.5 !default;
$payment-method-padding-y: $spacer !default;
$payment-method-transition: background-color .25s ease-in-out !default;

$payment-method-title-font-size: $font-size-lg !default;
$payment-method-title-font-weight: $font-weight-medium !default;

$payment-method-active-background: rgba($white, 0.1) !default;

.theme-rebit-material-dark {
    .eshop-payment-method-card {
        .payment-method {
            background: $payment-method-background;
            box-shadow: $payment-method-box-shadow;
            cursor: pointer;
            display: block;
            padding: $payment-method-padding-y $payment-method-padding-x;
            transition: $payment-method-transition;

            &.checked, &:hover {
                background: $payment-method-active-background;
            }
        }

        .payment-method-content {
            padding-left: $spacer*2;
        }

        .payment-method-title {
            font-size: $payment-method-title-font-size;
            font-weight: $payment-method-title-font-weight;
            margin-bottom: $spacer;
        }

        .cardpay-logoset {
            margin-top: $spacer*0.5;

            img {
                max-height: 22px;
                margin-right: $spacer*0.25;
            }
        }
    }
}
