@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .shop-service-commitment-select {
        align-items: center;
        display: flex;

        .dropdown-item {
            color: inherit;

            &:hover {
                background-color: rgba($white, 0.1);
            }
        }

        .dropdown-menu {
            background-color: $body-bg;
            border: 0;
            border-radius: 0;
            box-shadow: $box-shadow;
            color: $body-color;
            padding: 0;
        }

        .dropdown-toggle {
            border: 0;
            padding: 0;

            .mdi {
                margin-left: $spacer*0.5;
            }
        }
    }
}
