@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .screen-my-subscription {
        .my-subscription-list {
            tr:first-child td, tr:first-child th {
                border-top: 0;
            }

            tr td:nth-child(2) {
                text-align: right;
            }
        }
    }
}
