@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-video {
        .tv-channel-video-control {
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 20;

            .btn {
                color: $white !important;
                font-size: $font-size-base*1.5;
                padding: $spacer*0.5 $spacer;

                &:focus, &:hover {
                    color: $white !important;
                }
            }

            .control-play {
                left: 50%;
                transform: translateX(-50%);
            }

            .control-nav-channel {
                background-color: rgba($gray-900, 0.5);
                border-radius: 50%;
                font-size: $font-size-base*3 !important;
                height: $font-size-base*3 + $spacer*1.5;
                line-height: 1 !important;
                padding: 0 !important;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: $font-size-base*3 + $spacer*1.5;

                &:focus, &:hover {
                    background-color: rgba($gray-700, 0.5);
                }
            }

            .control-seek {
                height: 20px;

                &::after {
                    content: "";
                    background: rgba($black, 0.5);
                    display: block;
                    height: 8px;
                    width: 100%;
                }

                .progress {
                    background-color: rgba($white, 0.5);
                    height: 4px;
                    margin-top: 8px;
                    transition: transform 125ms ease-in-out;

                    .progress-bar {
                        background-color: $secondary;
                        height: inherit;
                        position: relative;

                        &::after {
                            content: "";
                            background-color: $secondary;
                            border-radius: 50%;
                            display: block;
                            height: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                            transition: height 125ms ease-in-out, width 125ms ease-in-out, right 125ms ease-in-out, top 125ms ease-in-out;
                            width: 0;
                        }
                    }
                }

                &:hover .progress {
                    transform: scaleY(1.5);

                    .progress-bar::after {
                        height: 8px;
                        right: -6px;
                        top: -2px;
                        width: 12px;
                    }
                }
            }

            .tv-channel-video-control-panel {
                align-items: center;
                background: rgba($black, 0.5);
                display: flex;
                flex-direction: row;

                .video-tv-channel-control {
                    flex-grow: 1;
                }
            }
        }

        &.paused .tv-channel-video-control:not(.seek) {
            background: rgba($black, 0.5);
        }

        &.waiting .tv-channel-video-control {
            background: rgba($black, 0.5);

            &::before {
                content: "\F0772";
                animation: mdi-spin 2s infinite;
                color: $white;
                font-family: "Material Design Icons";
                font-size: $font-size-base*3 !important;
                font-weight: bold;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
