@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .native-app-panel {
        :not(:first-child) .nav-card {
            @include media-breakpoint-down(sm) {
                margin-top: $spacer;
            }
        }
    }
}
