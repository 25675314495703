@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.layout-boxed {
    &.page-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .page-content {
        flex-grow: 1;
    }

    .page-header {
        background-color: $page-header-bg;
        box-shadow: $box-shadow;
    }

    &.authenticated .page-header {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .page-header .navbar {
        flex-grow: 1;
    }

    .page-header .navbar-nav {
        margin-bottom: $spacer;
        margin-top: $spacer;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .page-header .nav-link {
        align-items: center;
        border-bottom: 1px solid rgba($white, 0.05);
        border-radius: 0;
        display: flex;
        padding: $spacer*0.25 $spacer*1.5;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            flex-direction: column;
            padding: 0 $spacer;
        }
    }

    .page-header .nav-link .nav-icon {
        font-size: $font-size-lg;
        margin-right: $spacer;

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg * 2;
            margin-right: 0;
        }
    }

    .page-header .navbar-brand .logo {
        max-height: 50px;
    }

    .page-version {
        font-size: $font-size-sm;
        padding: 0 $spacer $spacer*0.25 $spacer;
        margin-top: -$spacer;
    }

    .page-footer {
        font-size: $font-size-sm;
        padding-bottom: $spacer*0.5;
        padding-top: $spacer*0.5;
        text-align: center;

        a {
            color: $gray-400;
            display: block;
            padding: $spacer*0.25 $spacer*0.5;
            transition: color .25s ease-in-out;

            @include media-breakpoint-up(lg) {
                display: inline-block;
            }

            &:hover {
                color: $white;
            }
        }
    }
}
