@import "~@rebitsk/bootstrap-rebit-theme/src/functions";
@import "~@rebitsk/bootstrap-rebit-theme/src/variables";
@import "~@rebitsk/bootstrap-rebit-theme/src/mixins";

$body-bg: $dark;
$body-color: $gray-100;

$card-border-width: 0;
$card-cap-bg: rgba($white, 0.05);
$card-bg: transparent;
$card-body-bg: rgba($white, 0.05);

$custom-scroll-color: rgba($gray-400, 0.15) !default;

$page-header-bg: rgba($black, 0.05) !default;

$toast-progress-height: 4px !default;

$tv-channel-control-icon-height: 60px !default;
$tv-channel-control-progress-height: 4px !default;

$tv-channel-guide-header-height: add($input-height, $spacer) !default;

$tv-channel-list-header-height: add($input-height, $spacer) !default;
