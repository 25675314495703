@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-channel-guide {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;

        &.expand {
            background: $body-bg;
            bottom: 0;
            left: $spacer*1.5;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 30000;
        }

        .tv-channel-guide-control-panel {
            display: flex;
            flex-direction: row;
            height: $tv-channel-guide-header-height;
            margin-bottom: $spacer;
            position: relative;
        }

        .tv-channel-guide-search-input {
            margin-bottom: 0;
            right: $spacer*2.5;

            &.open {
                background-color: rgba($white, 0.05);
                right: 0;
            }

            .form-control + .input-group-append .input-group-icon {
                font-size: $font-size-lg !important;
                font-weight: $font-weight-medium !important;
            }

            .input-group-text {
                margin-top: $spacer*0.25;
            }
        }

        .tv-channel-guide-title {
            align-items: center;
            background: rgba($white, 0.05);
            display: flex;
            font-weight: $font-weight-bold;
            flex-grow: 1;
            justify-content: space-between;
            padding: 0 0;
            text-transform: uppercase;

            & > .btn {
                color: $body-color;
                font-size: $font-size-lg;
                font-weight: bold;
                padding: $spacer;
            }
        }

        .tv-channel-guide-search-input.open + .tv-channel-guide-title {
            visibility: hidden;
        }
    }
}
