@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .video-level-switch-control {
        & + .video-level-switch-control-select {
            background-color: rgba($black, 0.5);
            bottom: $spacer*6;
            display: flex;
            flex-direction: column;
            position: absolute;
            right: $spacer*1.5;

            .video-level-switch-control-option {
                border-radius: 0;
            }
        }

        & + .video-level-switch-control-select .btn {
            color: $gray-100;
            font-size: $font-size-base !important;
            font-weight: $font-weight-medium !important;
            padding: $spacer*0.5 $spacer;
            text-align: right;
            width: 100%;

            &:hover {
                background-color: rgba($white, 0.5);
            }
        }

        & + .video-level-switch-control-select .btn > i {
            float: left;
            margin-right: $spacer*0.5;
        }
    }
}
