@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .tv-client-modal {
        margin-top: $spacer*9;

        .row:not(:last-child) {
            margin-bottom: $spacer*0.25;

            :first-child {
                font-weight: $font-weight-medium;
            }
        }

        .modal-footer {
            text-align: right;
        }
    }
}
