@import "src/ThemeRebitTvMaterialDark/import-bootstrap";

.theme-rebit-material-dark {
    .screen-tv-channel-video {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        gap: $spacer*1.5;
        height: 100% !important;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0 $spacer*3;
        overflow: hidden;
        width: 100vw;

        &.extended {
            padding: 0;
            height: 200% !important;

            .screen-tv-channel-video-control-panel {
                grid-column: span 2;
                grid-row: span 2;
                order: 2;
                padding-left: $spacer*3;
            }

            .screen-tv-channel-video-video {
                grid-column: span 5;
                grid-row: span 3;
                order: 1;
            }

            .screen-tv-channel-video-channel-guide {
                grid-column: span 3;
                grid-row: span 2;
                order: 3;
                padding-right: $spacer*3;
            }
        }

        .screen-tv-channel-video-channel-guide {
            grid-column: span 3;
            grid-row: span 2;

            .rcs-inner-container {
                scroll-snap-type: y proximity;
            }
        }

        .screen-tv-channel-video-video {
            grid-column: span 3;
            grid-row: span 3;

            .tv-channel-video {
                display: flex;
                height: 100%;
                justify-content: center;
                width: 100%;

                &:not(.paused) {
                    cursor: none;
                }

                .video {
                    min-height: 100%;
                    max-height: 100%;
                    width: auto;
                    max-width: 100%;
                }

                .tv-channel-video-control {
                    cursor: default;
                }
            }
        }

        .screen-tv-channel-video-control-panel {
            grid-column: span 2;
            grid-row: span 5;
        }
    }
}
