@import "../ThemeRebitTvMaterialDark/import-bootstrap";

input[type=range]::-moz-focus-outer {
    border: 0;
}

.alert {
    align-items: center;
    border: 0;
    display: flex;

    &.alert-danger {
        background: rgba($danger, 0.5);
        color: $body-color;
    }

    .alert-icon {
        font-size: $font-size-base*1.5;
        margin: 0 $spacer*1.5 0 $spacer*0.5;
    }
}

.form-group-material {
    label {
        display: none;
    }
}

.input-group-unmask {
    cursor: pointer;
}

.btn-link {
    color: darken($body-color, 7.5%);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, text-shadow 0.15s ease-in-out;

    &:focus, &:hover {
        color: $body-color;
        text-shadow: 0 0 1px rgba($white, 0.5);
    }
}

.modal {
    .modal-content {
        background-color: $body-bg;
        border-color: darken($body-bg, 7.5%);
        color: $body-color;
    }

    .modal-content .close {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;

        &:hover {
            background-color: transparent;
        }
    }

    .modal-header {
        border: 0;
    }

    .modal-footer {
        border: 0
    }
}

.card {
    background: $card-bg;
    border: $card-border-width solid $card-border-color;

    .card-header {
        background: $card-cap-bg;
        border: $card-border-width solid $card-border-color;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
    }

    .card-body {
        background: $card-body-bg;
    }

    .card-footer {
        background: $card-cap-bg;
        border: $card-border-width solid $card-border-color;
    }
}

.toast {
    .toast-title {
        font-size: $font-size-lg;
    }
}

.custom-control.custom-radio {
    padding-left: $spacer * 2;

    .custom-control-label::after,
    .custom-control-label::before {
        left: - $spacer * 2;
    }
}

.modal-dialog {
    pointer-events: all !important;
}
